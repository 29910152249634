




import { Component, Vue, Prop } from 'vue-property-decorator';
import AddRoomPopup from '@/modules/room-types/components/add-room.popup.vue';

@Component({
    components: { AddRoomPopup },
})
export default class AddEditRoomModalPage extends Vue {
    @Prop({ required: false })
    private roomTypeId?: number;
}
